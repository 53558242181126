import pkg from "../../../package.json";
import "./Footer.css";

export const Footer = () => {
    return (
        <footer id="public-footer">
            Copyright © 2023 <span>|</span> Aconto V{pkg.version} <span>|</span> All rights reserved
        </footer>
    );
};
