import { StaffState } from "../store/staffReducer";

export const SET_STAFF = "SET_STAFF";
export const ADD_STAFF = "ADD_STAFF";

export const SetStaff = (payload: StaffState[]) => {
    return { type: SET_STAFF, payload };
};

export const AddStaff = (added: StaffState) => {
    return { type: ADD_STAFF, payload: added };
};