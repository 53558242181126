import { Locale, UserData, UserRole } from "./userData";
import { IAssignment } from "./assignment";
import { ILocation } from "./locations";
import { SelectItemOptionsType } from "primereact/selectitem";
import { TranslateProps } from "../helpers/PropsTranslationHelper";
import { IEducation } from "./education";

export enum ContractType {
    FixedTermEmployment = 1,
    IndefiniteTermEmployment = 2,
    ServiceContact = 3,
    CopyrightAgreement = 4,
    StudentContract = 5,
    Internship = 6,
    Volunteering = 7,
}

export enum GenderType {
    Unknown,
    Male,
    Female,
    Other,
}

export enum TShirtSize {
    Unknown,
    ExtraSmall,
    Small,
    Medium,
    Large,
    ExtraLarge,
    ExtraExtraLarge,
}

export enum EmploymentType {
    Temporary,
    Permanent,
}

export interface UserDetails extends UserData {
    guid: string;
    firstName: string;
    lastName: string;
    userInfo: string;
    email: string;
    roles: UserRole[];
    locale: Locale;
    mentorGuid?: string;
    mentorName?: string;
    points: number;
    negativePoints: number;
    isEnabled: boolean;
    employeesViewEnabled: boolean;
    remoteEnabled: boolean;
    isEmployed: boolean;
    weeklyHours: number;
    employmentType: number;
    academicTitle?: string;
    userJobTitle: string;
    jobTitleGuid: string;
    jobDescription?: string;
    jobRequirements?: string;
    departmentName?: string;
    departmentActive?: boolean;
    departmentGuid?: string;
    startDate: string;
    endDate?: string;
    contractType?: ContractType;
    personalStatement?: string;
    oib?: string;
    address?: string;
    city?: string;
    country?: string;
    phoneNumber?: string;
    birthDate?: string;
    priorWorkExperience: number;
    cardNumber?: string;
    activeDirectoryUsername?: string;
    gitlabUsername?: string;
    clickupUsername?: string;
    assignments: IAssignment[];
    educations: IEducation[];
    languages: string[];
    companyName?: string;
    companyGuid?: string;
    location?: ILocation;
    accessRights?: string;
    genderType: GenderType;
    tShirtSize: TShirtSize;
    projects: string[];
    externalApproverEmail?: string;
    probationPeriodExpiration?: string;
    inventoryItems: string[];
    inventoryItemsGuids: string[];
    adminNotes?: string;
    ibanValues: string[];
    ibanGuids: string[];
    qrGuid?: string;
    projectGuids: string[];
    authorizedColleaguesForReports: string[];
    travelWarrantOperator?: boolean;
}

export interface INewUserDetails extends UserDetails {
    password: string;
    userName: string;
}

export const userDetailsInit: INewUserDetails = {
    password: "",
    userName: "",
    guid: "",
    firstName: "",
    lastName: "",
    userInfo: "",
    email: "",
    roles: [],
    locale: 0,
    points: 0,
    negativePoints: 0,
    isEnabled: false,
    weeklyHours: 0,
    employmentType: EmploymentType.Temporary,
    employeesViewEnabled: false,
    remoteEnabled: false,
    isEmployed: false,
    userJobTitle: "",
    jobTitleGuid: "",
    startDate: "0001-01-01",
    contractType: 0,
    priorWorkExperience: 0,
    assignments: [],
    educations: [],
    languages: [],
    genderType: 0,
    tShirtSize: 0,
    projects: [],
    inventoryItems: [],
    inventoryItemsGuids: [],
    ibanGuids: [],
    ibanValues: [],
    projectGuids: [],
    authorizedColleaguesForReports: [],
};

export interface VacationsDetail {
    guid: string;
    userGuid: string;
    year: number;
    correctionDays: number;
    contractDays: number;
    oldDays: number;
    daysUsed: number;
}

export const genderTypeOptions: SelectItemOptionsType = [
    {
        label: "general.unknown",
        value: 0,
    },
    {
        label: "Male",
        value: 1,
    },
    {
        label: "Female",
        value: 2,
    },
    {
        label: "Other",
        value: 3,
    },
];

export const employmentTypeOptions: SelectItemOptionsType = [
    {
        label: "Temporary",
        value: 0,
    },
    {
        label: "Permanent",
        value: 1,
    },
];

export const getEmploymentType = (type: EmploymentType) => {
    return TranslateProps(employmentTypeOptions)[type].label;
};

export const tShirtSizeOptions: SelectItemOptionsType = [
    {
        label: "Unknown",
        value: 0,
    },
    {
        label: "XS",
        value: 1,
    },
    {
        label: "S",
        value: 2,
    },
    {
        label: "M",
        value: 3,
    },
    {
        label: "L",
        value: 4,
    },
    {
        label: "XL",
        value: 5,
    },
    {
        label: "XXl",
        value: 6,
    },
];

export const getGenderType = (type: GenderType) => {
    return TranslateProps(genderTypeOptions)[type].label;
};

export const getTShirtSize = (type: TShirtSize) => {
    switch (type) {
        case 0:
            return "Unknown";
        case 1:
            return "XS";
        case 2:
            return "S";
        case 3:
            return "M";
        case 4:
            return "L";
        case 5:
            return "XL";
        case 6:
            return "XXL";
        default:
            return "";
    }
};
