import { Menubar } from "primereact/menubar";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IHeaderIcon } from "../../containers/Header/Header";
import LogoIcon from "../../icons/Aconto_logo.png";
import { getPublicNavigationItems } from "./HeaderItems";
import { Button } from "primereact/button";
import "./Header.css";

export const Header = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const HeaderIcon = ({ id, className, imgId }: IHeaderIcon) => (
        <Link id={id} className={className} to="/">
            <img src={LogoIcon} className="public-nav-logo" id={imgId} alt="" />
        </Link>
    );

    const onSignUpCLick = () => {
        navigate("/sign-up");
    };

    const HeaderEnd = () => (
        <div className="header-end">
            <Link to="/login">
                <FormattedMessage id="public.login" defaultMessage="Log in" />
            </Link>
            <Button
                className="p-button-sm"
                label="Sign up"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={onSignUpCLick}
            />
        </div>
    );

    return (
        <header className="public-header">
            <Menubar
                className="public-navigation"
                model={getPublicNavigationItems(dispatch, intl)}
                start={
                    <>
                        <HeaderIcon className="public-logo" imgId="public-logo-img" />
                        <b>
                            <span>Aconto</span>
                        </b>
                    </>
                }
                end={<HeaderEnd />}
            />
        </header>
    );
};
