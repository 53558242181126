import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { CalendarDateTemplateParams } from "primereact/calendar";
import { Button } from "primereact/button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import { externalVacationAction, getExternalVacationDetails } from "../../api/workAttendance";
import { TranslatedCalendar } from "../../components/TranslatedCalendar/TranslatedCalendar";
import { ExternalApproveStatus, IExternalVacationDetails } from "../../models/workAttendance";
import Messages from "../../localization/Messages";
import { showToastMessage } from "../../actions/toastMessageActions";
import { useDispatch } from "react-redux";
import { ApproveStatus } from "../../models/common";
import { LanguageSelect } from "../Header/LanguageSelect/LanguageSelect";
import "./ExternalVacationActions.css";

export const ExternalVacationActions = () => {
    const { guid } = useParams();
    const [query] = useSearchParams();
    const [details, setDetails] = useState<IExternalVacationDetails>();
    const [selectedDates, setSelectedDates] = useState<Date[] | undefined>([]);
    const [status, setStatus] = useState<ExternalApproveStatus>();
    const [comment, setComment] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<MessageDescriptor>();
    const dispatch = useDispatch();
    const intl = useIntl();

    const token = useMemo(() => {
        return query.get("token");
    }, [query]);

    const year = useMemo(() => new Date().getFullYear(), []);

    const fetchDetails = useCallback(async () => {
        if (guid && token) {
            try {
                const res = await getExternalVacationDetails(guid, token);
                if (res.workAttendance.status === ApproveStatus.Rejected) {
                    setErrorMsg(Messages.vacationRejectedByEmployer);
                }
                if (res.workAttendance.status === ApproveStatus.Approved) {
                    setErrorMsg(Messages.vacationApprovedByEmployer);
                }
                setDetails(res);
                setStatus(res.workAttendance.externalApproveStatus);
                setComment(res.workAttendance.externalApproveComment);
                setSelectedDates(res.workAttendance.dates.map(x => new Date(x.workDay)));
            } catch (e) {
                setErrorMsg(Messages.vacationNoLongerExists);
            }
        }
    }, [guid, token]);

    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);

    const dateTemplate = (date: CalendarDateTemplateParams) => {
        const dateLow = date.day <= 9;
        let currentlyIsSelected = false;

        selectedDates &&
            selectedDates.forEach(d => {
                if (
                    d &&
                    d.getDate() === date.day &&
                    d.getMonth() === date.month &&
                    d.getFullYear() === date.year
                ) {
                    currentlyIsSelected = true;
                }
            });
        return (
            <div
                className={classNames({
                    "number-design": true,
                    "low-number-design": dateLow,
                    "currently-selected-background": currentlyIsSelected,
                })}
            >
                {date.day}
            </div>
        );
    };

    const handleAction = async (approved: boolean) => {
        if (!guid || !token) return;
        try {
            setLoading(true);
            await externalVacationAction(guid, token, approved, comment);
            setStatus(approved ? ExternalApproveStatus.Approved : ExternalApproveStatus.Rejected);
        } catch {
            dispatch(showToastMessage(intl.formatMessage(Messages.errorTryAgain), "error"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="external-vacation-container">
            <div className="vacation-content">
                <LanguageSelect />
                {details && <h1>{intl.formatMessage(Messages.vacationDetails)}</h1>}

                {errorMsg && (
                    <>
                        <div className="error-msg-container">
                            <i className="pi icon pi-exclamation-circle" />
                            <h2>{intl.formatMessage(errorMsg)}</h2>
                        </div>
                        <p>{intl.formatMessage(Messages.noActionsNeeded)}</p>
                    </>
                )}

                {details && (
                    <>
                        <table className="dataview">
                            <tbody>
                                <tr>
                                    <th>
                                        <strong>{intl.formatMessage(Messages.name)}</strong>
                                    </th>
                                    <td>
                                        {details.firstName} {details.lastName}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <strong>{intl.formatMessage(Messages.email)}</strong>
                                    </th>
                                    <td>{details.email}</td>
                                </tr>
                                <tr>
                                    <th>
                                        <strong>{intl.formatMessage(Messages.companyName)}</strong>
                                    </th>
                                    <td>{details.companyName}</td>
                                </tr>
                                {details.jobTitle && (
                                    <tr>
                                        <th>
                                            <strong>{intl.formatMessage(Messages.jobTitle)}</strong>
                                        </th>
                                        <td>{details.jobTitle}</td>
                                    </tr>
                                )}
                                {selectedDates && (
                                    <tr>
                                        <th>
                                            <strong>{intl.formatMessage(Messages.dates)}</strong>
                                        </th>
                                        <td>
                                            {selectedDates
                                                .map(d => d.toLocaleDateString("hr"))
                                                .join(", ")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="work-attendances-container">
                            <TranslatedCalendar
                                inline
                                value={selectedDates}
                                disabled={true}
                                className="upload-and-calendar-width"
                                numberOfMonths={12}
                                showWeek={true}
                                viewDate={new Date(year, 0)}
                                dateTemplate={dateTemplate}
                            />
                        </div>

                        {!errorMsg && (
                            <>
                                <label>{intl.formatMessage(Messages.comment)}</label>
                                <InputTextarea
                                    disabled={!status || status !== ExternalApproveStatus.Pending}
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                    rows={5}
                                    autoResize
                                />

                                {status && status === ExternalApproveStatus.Pending ? (
                                    <div className="actions">
                                        <Button
                                            loading={loading}
                                            disabled={!guid}
                                            label={intl.formatMessage(Messages.approve)}
                                            onClick={handleAction.bind(null, true)}
                                            className="p-button-raised p-button-success"
                                        />
                                        <Button
                                            loading={loading}
                                            disabled={!guid}
                                            label={intl.formatMessage(Messages.reject)}
                                            onClick={handleAction.bind(null, false)}
                                            className="p-button-raised p-button-danger"
                                        />
                                    </div>
                                ) : (
                                    <p className="request-status">
                                        {status === ExternalApproveStatus.Rejected
                                            ? intl.formatMessage(
                                                  Messages.vacationRequestAlreadyRejected
                                              )
                                            : intl.formatMessage(
                                                  Messages.vacationRequestAlreadyApproved
                                              )}
                                    </p>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
