import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { setUserLocale } from "../../../actions/authentificationActions";
import { Locale } from "../../../models/userData";
import { HR, GB } from "country-flag-icons/react/3x2";
import "./LanguageSelect.css";
import { updateUserLocale } from "../../../api/users";

export const LanguageSelect = () => {
    const user = useSelector((state: AppState) => state.user);
    const dispatch = useDispatch();

    const handleChangeLanguage = async (e: DropdownChangeParams) => {
        if (user.guid && e.value !== user.locale) {
            try {
                await updateUserLocale(e.value);
                dispatch(setUserLocale(e.value));
                localStorage.setItem("user", JSON.stringify({ ...user, locale: e.value }));
            } catch {}
        } else {
            dispatch(setUserLocale(e.value));
        }
    };

    const options = [
        { label: "English", value: Locale.en },
        { label: "Hrvatski", value: Locale.hr },
    ];

    const onClick = () => {
        document.getElementById("language-select-dropdown")?.click();
    };

    const localeFlag = (locale: Locale) => {
        switch (locale) {
            case Locale.en:
                return (
                    <div className="locale-item">
                        <GB className="locale-flag" />
                    </div>
                );
            case Locale.hr:
                return (
                    <div className="locale-item">
                        <HR className="locale-flag" />
                    </div>
                );
        }
    };

    const localeOptionTemplate = (option: { label: string; value: Locale }) => {
        return localeFlag(option.value);
    };

    return (
        <div id="language-select" className="menu-item" onClick={onClick}>
            <Dropdown
                panelClassName="language-select-dropdown-panel"
                id="language-select-dropdown"
                options={options}
                onChange={handleChangeLanguage}
                optionLabel="value"
                itemTemplate={localeOptionTemplate}
                dropdownIcon="null"
            />
            <div className="select-flag">{localeFlag(user.locale ?? Locale.en)}</div>
            <i className="pi pi-angle-down" />
        </div>
    );
};
