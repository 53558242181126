import { Reducer } from "redux";
import { SET_COMPANY } from "../actions/companyActions";
import { ICompany } from "../models/company";

export const initialState: ICompany = {
    guid: "",
    phoneNumber: "",
    email: "",
    invoiceFooter: "",
    name: "",
};

export const companyReducer: Reducer<ICompany> = (
    state: ICompany = initialState,
    action: any
): ICompany => {
    switch (action.type) {
        case SET_COMPANY:
            return action.company;
        default:
            return state;
    }
};
