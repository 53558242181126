import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import enMessages from "./localization/compiled-lang/en.json";
import hrMessages from "./localization/compiled-lang/hr.json";
import { AppState } from "./store/configureStore";
import { Locale } from "./models/userData";

const DEFAULT_LOCALE = Locale.en;

// Same order as Locale enum
const langMessages = [enMessages, hrMessages];

const LanguageChange = (state: AppState) => {
    const locale = state.user.locale ?? DEFAULT_LOCALE;
    const messages = langMessages[locale];
    return { locale: Locale[locale], messages: messages };
};

export default connect(LanguageChange)(IntlProvider);
