import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { AcontoQuery } from "./axiosClient";
import { IPartner, IPartnerContact } from "../models/partner";
import { SelectItemOptionsType } from "primereact/selectitem";

export const getPartners = async (params: AcontoQuery) => {
    const res = await axios.get("/partners", { params: params });
    return res.data as IPaginationWrapper<IPartner>;
};

export const getPartner = async (guid: string) => {
    const response = await axios.get(`/partners/${guid}`);
    return response.data as IPartner;
};

export const createPartner = async (partnerDto: IPartner) => {
    return axios.post("/partners", partnerDto);
};

export const createPartnerContact = async (partnerContactDto: IPartnerContact) => {
    return axios.post("/partners/contact", partnerContactDto);
};

export const editPartner = async (partnerDto: IPartner) => {
    return axios.patch(`/partners/${partnerDto.guid}`, partnerDto);
};

export const deletePartner = async (guid: string) => {
    return axios.delete(`/partners/${guid}`);
};

export const deletePartnerContact = async (guid: string) => {
    return axios.delete(`/partners/contact/${guid}`);
};

export const editPartnerContact = async (partnerContactDto: IPartnerContact) => {
    return axios.patch(`/partners/contact/${partnerContactDto.guid}`, partnerContactDto);
};

export const getPartnersAsProps = async () => {
    const response = await axios.get(`/partners/props`);
    return response.data as SelectItemOptionsType;
};

export const getPartnerContactsAsProps = async (partnerGuids: string[]) => {
    const response = await axios.get(`/partners/contact/props`, { params: { partnerGuids } });
    return response.data as SelectItemOptionsType;
};

export const getPartnerContacts = async (query: AcontoQuery) => {
    const response = await axios.get(`/partners/contacts`, {
        params: query,
    });
    return response.data as IPaginationWrapper<IPartnerContact>;
};
