import { Reducer } from "redux";
import { ILanguage } from "../models/language";
import { SET_LANGUAGES } from "../actions/languageActions";

export const initialState: ILanguage[] = [];

export const languagesReducer: Reducer<ILanguage[]> = (
    state: ILanguage[] = initialState,
    action: any
): ILanguage[] => {
    switch (action.type) {
        case SET_LANGUAGES:
            return action.languages
        default:
            return state;
    }
};