import { useEffect } from "react";
import { useSelector } from "react-redux";
import { locale, addLocale } from "primereact/api";
import { AppState } from "../../store/configureStore";
import { Calendar, CalendarProps } from "primereact/calendar";
import { Locale } from "../../models/userData";
import enLangSettings from "./en.json";
import hrLangSettings from "./hr.json";

export const TranslatedCalendar = (props: CalendarProps) => {
    const user = useSelector((state: AppState) => state.user);

    useEffect(() => {
        addLocale("custom", user.locale === Locale.en ? enLangSettings : hrLangSettings);
        locale("custom");
    }, [user.locale]);

    return <Calendar {...props} />;
};
