import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { AppState } from "../../store/configureStore";
import { Link } from "react-router-dom";

export const ToastMessage = () => {
    const toast = useRef<Toast>(null);
    const { text, life, severity, link, sticky, timestamp } = useSelector(
        (state: AppState) => state.toastMessage
    );

    useEffect(() => {
        if (toast.current && timestamp !== 0) {
            const detail = link ? <Link to={link}>{text}</Link> : text;
            toast.current.show({ closable: true, life, sticky, severity, detail });
        }
    }, [text, life, sticky, severity, timestamp, link]);

    return <Toast position="bottom-right" ref={toast} />;
};
