import axios from "axios";
import { DashboardNotifications } from "../models/dashboardNotifications";
import { SearchData } from "../models/searchData";
import { IHomeDataWrapper } from "../models/workAttendance";
import { AppStageState } from "../store/appStageReducer";

export const getSearchBarData = async (search: string) => {
    const res = await axios.get(`/home/${search}`);
    return res.data as SearchData[];
};

export async function getAppStageData(): Promise<AppStageState> {
    const res = await axios.get(`/home/stage`);
    return res.data as AppStageState;
}

export async function getDashboardNotifications(): Promise<DashboardNotifications> {
    const res = await axios.get(`/home/notifications`);
    return res.data as DashboardNotifications;
}

export const getHomepageData = async () => {
    const res = await axios.get("/Home/homepagedata");
    return res.data as IHomeDataWrapper;
};