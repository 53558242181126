import { Dispatch, Reducer } from "redux";
import { GET_PROJECT_ROLES } from "../actions/projectRolesActions";
import { getProjectRolesAsProps } from "../api/projectRoles";

export interface ProjectRolesState {
    // SelectItem!
    label: string;
    value: string;
}

export const initialState: ProjectRolesState[] = [];

export const projectRolesReducer: Reducer<ProjectRolesState[]> = (
    state: ProjectRolesState[] = initialState,
    action: any
): ProjectRolesState[] => {
    switch (action.type) {
        case GET_PROJECT_ROLES:
            return action.payload;
        default:
            return state;
    }
};

export const fetchProjectRoles = async (dispatch: Dispatch) => {
    const res = await getProjectRolesAsProps();
    dispatch({ type: "GET_PROJECT_ROLES", payload: res });
};

export const getRoleName = (role: string | undefined) => {
    if (role) {
        const clean = role.replace(/_/g, " ");
        return clean.charAt(0).toUpperCase() + clean.slice(1);
    } else return "";
};
