import { Reducer } from "redux";
import { SET_NOTIFICATIONS, ADD_NOTIFICATION, MARK_NOTIFICATIONS_AS_READ } from "../actions/notificationsActions";
import { INotification } from "../models/notification";

export const initialState: INotification[] = [];

export const notificationsReducer: Reducer<INotification[]> = (
    state: INotification[] = initialState,
    action: any
): INotification[] => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return action.payload;
        case ADD_NOTIFICATION:
            return [action.payload, ...state];
        case MARK_NOTIFICATIONS_AS_READ:
            return state.filter(n => !action.payload.includes(n.guid));
        default:
            return state;
    }
};