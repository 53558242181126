import { MenuItem } from "primereact/menuitem";
import { UserData, UserRole } from "../models/userData";
import { Link } from "react-router-dom";
import { UserHasRoles } from "./RolesHelper";
import { IntlShape } from "react-intl";
import { Icons } from "../icons/NavigationIcons";

export interface AcontoMenuItem extends MenuItem {
    link?: string;
    url?: undefined;
    roles?: UserRole[];
    items?: AcontoMenuItem[];
}

export const getMenuLinkItem = (
    item: AcontoMenuItem,
    withIcons: boolean = false
) => {
    const result = {
        ...item,
        icon: item.icon && withIcons ? Icons[item.icon as string] : item.icon,
    };
    return item.link
        ? {
              ...result,
              template: (
                  <Link className="p-menuitem-link" role="menuitem" to={item.link}>
                      {typeof result.icon !== 'string' && result.icon}
                      <span className="p-menuitem-text">{item.label}</span>
                  </Link>
              ),
          }
        : result;
};

const filterMenuItemsRecursive = (
    items: AcontoMenuItem[] | undefined,
    user: UserData,
    intl: IntlShape,
    withIcons: boolean = false
) => {
    if (items) {
        const results: AcontoMenuItem[] = [];
        items.forEach(item => {
            let subitems = filterMenuItemsRecursive(item.items, user, intl);
            if (!item.roles || UserHasRoles(user, item.roles)) {
                let translatedLabel = item.label && intl.formatMessage({ id: item.label });
                results.push(
                    getMenuLinkItem(
                        { ...item, label: translatedLabel, items: subitems },
                        withIcons
                    )
                );
            }
        });
        return results;
    } else return undefined;
};

export const filterMenuItems = (
    items: AcontoMenuItem[] | undefined,
    user: UserData,
    intl: IntlShape,
    withIcons: boolean = false
) => {
    const result = filterMenuItemsRecursive(items, user, intl, withIcons);
    return result ?? [];
};
