import { Button } from "primereact/button";
import { Layout } from "../Layout/Layout";
import { ReactComponent as FeaturesSvg } from "./icons/Ikoneplustext.svg";
import { ReactComponent as IntroSvg } from "./icons/3cudovista.svg";
import { ReactComponent as TextBubbleMonster } from "./icons/cudovistasprozorom.svg";

import "./Landing.css";

export const Landing = () => {
    return (
        <Layout>
            <div className="intro section left dark down">
                <div className="content-wrapper">
                    <div className="section-content">
                        <h1>
                            Business
                            <br /> administration
                            <br /> made easy
                        </h1>
                        <hr />
                        <p>Business administration software for small enterprises.</p>
                        <Button label="Get started" className="p-button-info" />
                    </div>
                    <div className="section-images">
                        <IntroSvg />
                    </div>
                </div>
            </div>
            <div id="why-aconto" className="why-aconto section right light">
                <div className="content-wrapper">
                    <div className="section-images">
                        <img
                            src="/landing/Mockup_mobile-desktop.png"
                            alt="Mobile and Desktop mockup"
                        />
                    </div>
                    <div className="section-content">
                        <h1>
                            Why
                            <br /> Aconto?
                        </h1>
                        <hr />
                        <p>
                            Aconto has been developed through extensive market research of small
                            business owners' needs. Easy to use and up to 5 times cheaper than
                            similar products, Aconto is everything you need to avoid getting stuck
                            on manual administrative work.
                        </p>
                        <Button label="Try it now" className="p-button-help" />
                    </div>
                </div>
            </div>

            <div id="product-features" className="paperwork section left dark up">
                <div className="content-wrapper">
                    <div className="section-content">
                        <h1>
                            Reduce paperwork
                            <br /> and streamline
                            <br /> workflow
                        </h1>
                        <hr />
                        <p>
                            We have thought carefully about product features an all-in-one solution
                            for business administration necessitates. We have identified the core
                            six, which most businesses need to manage effectively to function.
                        </p>
                        <Button label="Get started" className="p-button-danger" />
                    </div>
                    <div className="section-images desktop">
                        <FeaturesSvg />
                    </div>

                    <div className="section-images mobile">
                        <div className="features-card">
                            <img src="/landing/Employees_icon.svg" alt="employees ilustration" />
                            <div>
                                <h3>Employees</h3>
                                <span>
                                    Manage time sheets, buy requests, remote work, (un)paid leaves,
                                    and vacations.
                                </span>
                            </div>
                        </div>
                        <div className="features-card">
                            <img src="/landing/Partners_icon.svg" alt="partners ilustration" />
                            <div>
                                <h3>Partners</h3>
                                <span>
                                    Create partner lists with company details and contacts. Classify
                                    types of partnerships.
                                </span>
                            </div>
                        </div>
                        <div className="features-card">
                            <img src="/landing/Projects_icon.svg" alt="projects ilustration" />
                            <div>
                                <h3>Projects</h3>
                                <span>
                                    Add projects with assigned partners, project members and
                                    invoices. Check and export relevant reports.
                                </span>
                            </div>
                        </div>
                        <div className="features-card">
                            <img src="/landing/Invoicing_icon.svg" alt="invoicing ilustration" />
                            <div>
                                <h3>Invoicing</h3>
                                <span>Upload bank statements and invoices and plan payments.</span>
                            </div>
                        </div>
                        <div className="features-card">
                            <img src="/landing/Inventory_icon.svg" alt="inventory ilustration" />
                            <div>
                                <h3>Inventory</h3>
                                <span>
                                    List inventory items and appoint employees responsible for their
                                    care.
                                </span>
                            </div>
                        </div>
                        <div className="features-card">
                            <img src="/landing/News feed_icon.svg" alt="news feed ilustration" />
                            <div>
                                <h3>News feed</h3>
                                <span>
                                    Monitor all daily activities through a centralized news feed.{" "}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="features-line down dark" />
                </div>
            </div>

            <div className="do-more section right light down">
                <div className="content-wrapper">
                    <div className="section-images">
                        <TextBubbleMonster />
                    </div>
                    <div className="section-content">
                        <h1>
                            Do more
                            <br /> with less
                        </h1>
                        <hr />
                        <p>
                            Did you know that executives are 3 to 4 times more likely to handle
                            administrative tasks and spend 16 hours a week on administration? Aconto
                            will save the time spent on business administration allowing you instead
                            to focus on growing your business.
                        </p>
                        <Button label="Try it now" />
                    </div>
                </div>
            </div>
        </Layout>
    );
};
