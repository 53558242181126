import { Dispatch, Reducer } from "redux";
import { GET_PARTNERS } from "../actions/partnersActions";
import { getPartnersAsProps } from "../api/partners";

export interface PartnersState {
    label: string;
    value: string;
}

export const initialState: PartnersState[] = [];

export const partnersReducer: Reducer<PartnersState[]> = (
    state: PartnersState[] = initialState,
    action: any
): PartnersState[] => {
    switch (action.type) {
        case GET_PARTNERS:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchPartners(dispatch: Dispatch) {
    const res = await getPartnersAsProps();
    dispatch({ type: "GET_PARTNERS", payload: res });
}
