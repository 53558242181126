import Snowfall, { SnowfallProps } from "react-snowfall";

const snowflake = document.createElement("img");
snowflake.src = "/flake.png";

const currentDate = new Date();
const snowfallStart = new Date(currentDate.getFullYear(), 11, 1);
const snowfallEnd = new Date(currentDate.getFullYear(), 0, 8);
const showSnowfall = currentDate >= snowfallStart || currentDate <= snowfallEnd;

const snowflakeProps: SnowfallProps = {
    style: { zIndex: 10000, display: showSnowfall ? "block" : "none" },
    snowflakeCount: 400,
    images: [snowflake],
    radius: [8, 16] as [number, number],
};

const AcontoSnow = () => {
    return <Snowfall {...snowflakeProps} />;
};

export default AcontoSnow;
