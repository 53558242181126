import { AxiosStatic } from "axios";
import { DataTablePFSEvent } from "primereact/datatable";
import { UserData } from "../models/userData";
import packageJson from "../../package.json";

const DEFAULT_API_VERSION = 3;

export const configureAxiosClient = (axios: AxiosStatic) => {
    axios.interceptors.request.use(config => {
        const userData = localStorage.getItem("user");
        if (userData) {
            const user = JSON.parse(userData) as UserData;
            config.headers!.Authorization = `Bearer ${user.token}`;
        }
        return config;
    });
    axios.interceptors.request.use(config => {
        const apiVersion = config.apiVersion ?? packageJson.activeApiVersion ?? DEFAULT_API_VERSION;
        config.baseURL = config.baseURL ?? `/api/v${apiVersion}/`;
        return config;
    });
    axios.interceptors.request.use(config => {
        config.paramsSerializer = {
            indexes: null,
        };
        return config;
    });
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response.status === 403) {
                window.location.replace('/unauthorized');
            }
            console.error(error);
            return Promise.reject(error);
        }
    );
};

export interface AcontoQuery extends Omit<DataTablePFSEvent, "filters"> {
    filters?: string[];
}

declare module "axios" {
    export interface AxiosRequestConfig {
        apiVersion?: number;
    }
}
