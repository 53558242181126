import { IntlShape } from "react-intl";
import { AcontoMenuItem, filterMenuItems } from "../../helpers/MenuItemHelper";
import { AppDispatch } from "../../store/configureStore";

export const getPublicNavigationItems = (dispatch: AppDispatch, intl: IntlShape) => {
    const items: AcontoMenuItem[] = [
        {
            label: "Why Aconto?",
            link: "/why-aconto",
        },
        {
            label: "Product features",
            link: "/product-features",
        },
        {
            label: "Questions",
            link: "/questions",
        },
    ];

    return filterMenuItems(items, {}, intl, false);
};
