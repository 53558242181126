import { useLocation, Routes, Route } from "react-router-dom";
import { ExternalVacationActions } from "../ExternalVacationActions/ExternalVacationActions";

export const ExternalPages = () => {
    const location = useLocation();

    return (
        <Routes location={location}>
            <Route path="/vacation/:guid" element={<ExternalVacationActions />} />
        </Routes>
    );
};
