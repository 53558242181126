import axios from "axios";
import { ApproveStatus, IPaginationWrapper } from "../models/common";
import {
    IExternalVacationAction,
    IExternalVacationDetails,
    IMonthlyWorkAttendance,
    IUserDto,
    IWorkAttendance,
    WorkAttendanceType,
    ITableData,
} from "../models/workAttendance";
import { IWorkDate } from "../models/workDate";
import { AcontoQuery } from "./axiosClient";
import { IVacationHeatmapData } from "../models/vacationHeatmapData";

export const getWorkAttendances = async (
    query: AcontoQuery,
    attendanceType: WorkAttendanceType,
    approveStatus: ApproveStatus
) => {
    const res = await axios.get(`/WorkAttendances/${attendanceType}/${approveStatus}`, {
        params: { ...query },
    });
    return res.data as IPaginationWrapper<IWorkAttendance>;
};

export const getIsYearlyVacationCreated = async () => {
    const res = await axios.get(`/WorkAttendances/isCreated`);
    return res.data;
};

export const getUsersWithoutYearlyVacationPlan = async () => {
    const res = await axios.get(`/WorkAttendances/withoutPlan`);
    return res.data;
};

export const remindUsersWithoutYearlyVacationPlan = async () => {
    const res = await axios.get(`/WorkAttendances/remindUsersWithoutPlan`);
    return res.data;
};

export const getWorkAttendance = async (guid: string) => {
    const res = await axios.get(`/WorkAttendances/${guid}`);
    return res.data as { workAttendance: IWorkAttendance; vacationDaysLeft: number };
};

export const getPreviouslySelectedDates = async (guid?: string, userGuid?: string) => {
    const res = await axios.get("/WorkAttendances/previous", { params: { guid, userGuid } });
    return res.data as IWorkDate[];
};

export const postWorkAttendance = async (workAttendance: IWorkAttendance, files?: File[]) => {
    const formData = new FormData();
    formData.append("workAttendanceDtoString", JSON.stringify(workAttendance));
    if (files) {
        for (const file of files) {
            formData.append("files", file);
        }
    }
    await axios.post("/WorkAttendances", formData);
};

export const patchWorkAttendance = async (workAttendance: IWorkAttendance, files?: File[]) => {
    const formData = new FormData();
    formData.append("workAttendanceDtoString", JSON.stringify(workAttendance));
    if (files) {
        for (const file of files) {
            formData.append("files", file);
        }
    }
    await axios.patch(`/WorkAttendances/${workAttendance.guid}`, formData);
};

export const deleteWorkAttendance = async (guid: string) => {
    await axios.delete(`/WorkAttendances/${guid}`);
};

export const requestDeleteWorkAttendance = async (guid: string) => {
    return axios.post(`/WorkAttendances/requestDelete/${guid}`);
};

export const processWorkAttendance = async (guid: string, choice: boolean) => {
    await axios.post(`/WorkAttendances/${guid}/process`, JSON.stringify(choice), {
        headers: { "Content-Type": "application/json" },
    });
};

export const getVacationDaysLeft = async (guid: string, isYearlyPlan: boolean) => {
    const res = await axios.get(`/WorkAttendances/vacationDaysLeft/${guid}`, {
        params: { isYearlyPlan },
    });
    return res.data as number;
};

export const getYearlyVacationPlanYearsAsProps = async () => {
    const res = await axios.get(`/WorkAttendances/yearsProps`);
    return res.data;
};

export const getEmployeeWorkAttendances = async (staffData?: IUserDto) => {
    const res = await axios.post("/WorkAttendances/EmployeeWorkAttendances", staffData ?? {});
    return res.data as ITableData[];
};

export const getMonthlyWorkAttendances = async (
    month: number,
    year = new Date().getFullYear(),
    guids?: string[]
) => {
    const res = await axios.get("/WorkAttendances/monthlyWorkAttendances", {
        params: { month, guids, year },
    });
    return res.data as IMonthlyWorkAttendance[];
};

export const downloadMonthlyWorkAttendances = async (
    month: number,
    guids?: string[],
    year = new Date().getFullYear()
) => {
    const response = await axios.get("/WorkAttendances/downloadMonthly", {
        responseType: "blob",
        params: { month, guids, year },
    });

    const url = window.URL.createObjectURL(
        new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
    );
    const link = document.createElement("a");

    const filename = response.headers["content-disposition"]
        ?.split(";")[1]
        ?.split("filename=")[1]
        ?.replaceAll('"', "");

    link.href = url;
    link.setAttribute("download", filename ?? "monthly_report.xlsx");
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
};

export const getFreeDays = async () => {
    const res = await axios.get("/WorkAttendances/freeDays");
    return res.data as IWorkDate[];
};

export const getExternalVacationDetails = async (guid: string, token: string) => {
    const res = await axios.get(`/WorkAttendances/external/${guid}`, { params: { token } });
    return res.data as IExternalVacationDetails;
};

export const externalVacationAction = async (
    guid: string,
    token: string,
    isApproved: boolean,
    comment: string | undefined
) => {
    const body: IExternalVacationAction = { isApproved, token, comment };
    return axios.patch(`/WorkAttendances/external/${guid}`, body);
};

export const addDocumentsToWorkAttendance = async (files: File[], workAttendanceGuid: string) => {
    let formData = new FormData();
    for (const file of files) {
        formData.append("files", file);
    }

    formData.append("workAttendanceGuid", workAttendanceGuid);
    await axios.post(`/workAttendances/addDocuments`, formData);
};

export const getAllVacationData = async (year: number) => {
    const res = await axios.get(`WorkAttendances/All/${year}`);
    return res.data as IVacationHeatmapData[];
};
