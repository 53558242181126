import { SelectItem } from "primereact/selectitem";
import { useIntl } from "react-intl";

export const TranslateProps = (props: SelectItem[], sort: boolean = false) => {
    const intl = useIntl();
    const translated: SelectItem[] = props.map(x => ({
        ...x,
        label: intl.formatMessage({ id: x.label as string }),
    }));
    if (sort)
        return translated.sort((a, b) => (a.label as string).localeCompare(b.label as string));
    else return translated;
};
