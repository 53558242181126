import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import "./Layout.css";

export const Layout = (props: any) => {
    return (
        <div id="public-layout">
            <Header />
            <main className="public-main">{props.children}</main>
            <Footer />
        </div>
    );
};
