import { Dispatch, Reducer } from "redux";
import { GET_PROJECTS } from "../actions/projectsActions";
import { getProjectsAsProps } from "../api/projects";

export interface ProjectsState {
    label: string;
    value: string;
}

export const initialState: ProjectsState[] = [];

export const projectsReducer: Reducer<ProjectsState[]> = (
    state: ProjectsState[] = initialState,
    action: any
): ProjectsState[] => {
    switch (action.type) {
        case GET_PROJECTS:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchProjects(dispatch: Dispatch) {
    const res = await getProjectsAsProps();
    dispatch({ type: "GET_PROJECTS", payload: res });
}
