export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";

export type IToastSeverity = "success" | "info" | "warn" | "error";

export const showToastMessage = (
    text: string,
    severity: IToastSeverity = "info",
    link: string | undefined = undefined,
    life = 3000,
    sticky = false
) => {
    text = text.replace(/[#]|[\|]/g, ""); // remove delimeters used in language JSONs for coloring notifications
    return { type: SHOW_TOAST_MESSAGE, text, link, severity, life, sticky };
};
