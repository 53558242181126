import React from "react";
import { ReactComponent as Finance } from "./Finance.svg";
import { ReactComponent as Company } from "./Company.svg";
import { ReactComponent as Contacts } from "./Contacts.svg";
import { ReactComponent as Settings } from "./Settings.svg";
import { ReactComponent as Staff } from "./Staff.svg";
import { ReactComponent as MyData } from "./My data.svg";
import { ReactComponent as Reports } from "./Reports.svg";

export interface IIcons {
    [key: string]: React.ReactNode;
}

export const Icons: IIcons = {
    Finance: <Finance />,
    Company: <Company />,
    Contacts: <Contacts />,
    Settings: <Settings />,
    Staff: <Staff />,
    MyData: <MyData />,
    Reports: <Reports />,
};
