import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IProjectRole } from "../models/projectRole";
import { ProjectRolesState } from "../store/projectRolesReducer";
import { AcontoQuery } from "./axiosClient";

export const getProjectRoles = async (query: AcontoQuery) => {
    const response = await axios.get(`/projectRoles`, { params: query });
    return response.data as IPaginationWrapper<IProjectRole>;
};

export const getProjectRole = async (guid: string) => {
    const response = await axios.get(`/projectRoles/${guid}`);
    return response.data as IProjectRole;
};

export const createProjectRole = async (projectRole: IProjectRole) => {
    return axios.post("/projectRoles", projectRole);
};

export const editProjectRole = async (projectRole: IProjectRole) => {
    return axios.patch(`/projectRoles/${projectRole.guid}`, projectRole);
};

export const deleteProjectRole = async (guid: string) => {
    return axios.delete(`/projectRoles/${guid}`);
};

export const getProjectRolesAsProps = async () => {
    const response = await axios.get(`/projectRoles/props`);
    return response.data as ProjectRolesState[];
};
