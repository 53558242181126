import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IProject } from "../models/project";
import { ProjectsState } from "../store/projectsReducer";
import { AcontoQuery } from "./axiosClient";
import { DropdownProps } from "primereact/dropdown";

export const getProjects = async (params: AcontoQuery, archived?: boolean) => {
    const res = await axios.get("/projects", { params: { ...params, archived: archived } });
    return res.data as IPaginationWrapper<IProject>;
};

export const getProject = async (guid: string) => {
    const res = await axios.get(`/projects/${guid}`);
    return res.data as IProject;
};

export const postProject = async (dto: IProject) => {
    const res = await axios.post("/projects", dto);
    return res.data;
};

export const patchProject = async (dto: IProject) => {
    await axios.patch(`/projects/${dto.guid}`, dto);
};

export const deleteProject = async (guid: string) => {
    await axios.delete(`/projects/${guid}`);
};

export const getProjectsAsProps = async () => {
    const response = await axios.get(`/projects/props`);
    return response.data as ProjectsState[];
};

export const getProjectUsersAsProps = async (guid: string, date:string) => {
    const response = await axios.get(`/Projects/props/${guid}/${date}`);
    return response.data as DropdownProps[];
};

export const editProjectArchiveStatus = async (guid: string) => {
    await axios.patch(`/projects/archive/${guid}`);
};
