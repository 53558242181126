export interface ISignup {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
}

export const signupInit: ISignup = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
};
