import { INotification } from "../models/notification";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const MARK_NOTIFICATIONS_AS_READ = "MARK_NOTIFICATIONS_AS_READ";

export const SetNotifications = (payload: INotification[]) => {
    return { type: SET_NOTIFICATIONS, payload };
};

export const AddNotification = (payload: INotification) => {
    return { type: ADD_NOTIFICATION, payload };
};

export const MarkNotificationsAsRead = (payload: string[]) => {
    return { type: MARK_NOTIFICATIONS_AS_READ, payload };
};
